import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/Intro'
import Content from '../components/Content'
import Map from '../components/Map'

const Main = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  width: 66.666666%;
  padding-right: 50px;

  @media (max-width: 1024px) {
    order: 2;
    width: auto;
    padding-right: 0;
  }
`

const Right = styled.div`
  width: 33.333333%;

  @media (max-width: 1024px) {
    margin: -3em 0 3em 0;
    width: auto;
  }
`

const Contact = styled.div`
  a {
    color: inherit;
  }
`

const Label = styled.h3`
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-size: 0.75em;
  opacity: 0.4;
`

const KontaktPage = () => (
  <Layout>
    <SEO
      title="Kontakt"
      keywords={[
        `Apps`,
        `Websites`,
        `Webseiten`,
        `Entwicklung`,
        `Programmierung`,
        `Homepage`,
        `Digital`,
      ]}
    />
    <Intro
      meta="Kontakt"
      main={
        <>
          Unsere Erreichbarkeit:
          <br /> Von jetzt bis immer.
        </>
      }
    />
    <Content>
      <Main>
        <Left>
          <Map />
        </Left>
        <Right>
          <Contact>
            <br />
            <br />
            <br />
            <Label>Telefon</Label>
            +423 230 30 32
            <br />
            <br />
            <Label>E-Mail</Label>
            <a href="mailto:hallo@a45.li">hallo@a45.li</a>
            <br />
            <br />
            <Label>Adresse</Label>
            A45 Anstalt
            <br />
            Äulestrasse 45
            <br />
            9490 Vaduz
            <br />
            Liechtenstein
          </Contact>
        </Right>
      </Main>
      <br />
      <br />
      <br />
    </Content>
  </Layout>
)

export default KontaktPage
