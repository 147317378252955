import React from 'react'
import styled from 'styled-components'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import Content from './Content'

const styles = [
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f2f1ef',
      },
    ],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.government',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const MyMapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 47.139292, lng: 9.521788 }}
      defaultOptions={{ styles }}
    >
      {props.isMarkerShown && (
        <Marker position={{ lat: 47.139292, lng: 9.521788 }} />
      )}
    </GoogleMap>
  ))
)

const Wrapper = styled.div`
  background: #f2f1ef;
`

const StyledContent = styled(Content)`
  position: relative;
  padding-left: 0;
  padding-right: 0;

  &:before,
  &:after {
    content: '';
    width: 48px;
    background: #fff;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;

    @media (max-width: 1680px) {
      display: none;
    }
  }

  &:before {
    left: -48px;
  }

  &:after {
    right: -48px;
  }
`

const MapContainer = styled.div`
  height: 50vh;
  /*height: 66.666666vh;*/
  min-height: 320px;
`

const Map = () => (
  <Wrapper>
    <StyledContent>
      <MyMapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBEJZCXCp1mtyFo5sRt4a1UC5wBZPrb5sQ&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<MapContainer />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </StyledContent>
  </Wrapper>
)

export default Map
